.contact {
    background-color: #fff3e0;
    padding: 2rem;
  }
  
  .contact h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .contact form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact form label {
    font-weight: bold;
  }
  
  .contact form input, .contact form textarea {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact form button {
    background-color: var(--color-main);
    color: var(--color-text);
    font-weight: 600;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .contact form button:hover {
    background-color: var(--color-text);
  color: var(--color-main);
  }


@media (max-width: 768px) {
  .contact h2 {
    font-size: 1.5rem;
  }

  .contact form {
    gap: 0.5rem;
    width: 90%;
  }

  .contact form label {
    font-size: 0.875rem;
  }

  .contact form input, .contact form textarea {
    font-size: 0.875rem;
    padding: 0.4rem;
  }

  .contact form button {
    font-size: 0.875rem;
    padding: 0.4rem 0.8rem;
  }
}
  
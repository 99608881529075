.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 3px;
  background: var(--color-background);
}

.logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text);
}

.logo span {
  color: var(--color-main);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 100px;
}

.navbar .nav-menu {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  position: relative;
  justify-content: space-between;
  margin: 0; 
}

.navbar .nav-menu a {
  font-size: 1rem;
  padding: 10px 20px;
  font-weight: 500;
  color: var(--color-text);
}

.navbar .nav-menu a:hover {
  text-decoration: none;
  color: var(--color-main);
}

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.burger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: var(--color-text);
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.burger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.burger-menu.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -5px);
}

@media (max-width: 768px) {

  .logo {
    font-size: 1.2rem;
    padding-left: 20px;
  }

  .burger-menu {
    display: block;
    right: 32px;
    position: absolute;
  }

  .nav-menu {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--color-background);
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }

  .nav-menu.open {
    max-height: 500px;
    padding-bottom: 34px;
    top:18px;
    right: 200px;
    left: -245px;
  }

  .nav-menu li {
    text-align: center;
    width: 100%;
  }

  .nav-menu a {
    padding: 15px 0;
    display: block;
    width: 100%;
  }
}

.about {
  background-color: var(--color-main-background);
  padding: 2rem;
}

.about-content {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.background-img {
  width: 50%;
  border-radius: 8px;
  padding-left: 20px;
}

.text-content {
  flex: 1;
  text-align: left;
  padding: 10px;
}

.about h4 {
  font-size: 1.5rem;
  color: var(--color-main);
  margin-bottom: 1rem;
  padding-top: 2rem;
}

.about h2 {
  font-size: 2rem;
  color: var(--color-text);
  margin-bottom: 1rem;
}

.about h3 {
  font-size: 1.2rem;
  color: var(--color-text);
  margin-bottom: 5rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feature {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.feature i {
  font-size: 2rem;
  color: var(--color-main);
}

.feature p {
  font-size: 1rem;
  color: var(--color-text);
}

@media (max-width: 1280px) {

.background-img {
  height: 140vh;
  object-fit: cover
    
  }

}

@media (max-width: 870px) {

  .background-img {
    display: none;
  }
  
  }

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .text-content {
    text-align: justify;
  }

  .about h4 {
    font-size: 1rem;
  }

  .about h2 {
    font-size: 1.2rem;
  }

  .about h3 {
    font-size: .8rem;
    margin-bottom: 3rem;
  }

  .feature i {
    font-size: 1.5rem;
  }

  .feature p {
    font-size: 0.875rem;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

section {
  padding: 2rem;
}

footer {
  background-color: var(--color-text);
  color: var(--color-main-background);
  padding: 1rem;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  padding-left: 30px;
}

.contact-info, .terms-info {
  flex: 1;
}

.contact-info p, .terms-info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.terms-info a {
  color: var(--color-main-background);
  text-decoration: none;
}

.terms-info a:hover {
  color: var(--color-main);
}

.home, .about, .contact, .products {
  padding: 2rem 0;
}


@media (max-width: 1280px) {
.contact-info, .terms-info {
  font-size: 10px;
  text-align: left;
  gap: 5rem;
}
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    padding: 10px;
    font-size: small;
  }

  .terms-info {
    padding-top: 20px;
  }


}

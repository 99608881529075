.products {
  padding: 2rem 0;
  background-color: var(--color-background);
}

.products h2 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 2rem;
  color: var(--color-text);
}

.product-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.product-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1400px;
  width: 100%;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 30px 50px 30px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upper-part {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.product-image {
  width: 150px;
  height: auto;
  border-radius: 8px;
}

.product-info {
  flex: 1;
}

.product-info h3 {
  margin: 0;
  font-weight: bold;
  color: var(--color-text);
  font-size: xx-large;
}

.product-info p {
  margin: 0.5rem 0;
  color: var(--color-text);
  font-weight: bold;
  font-size: x-large;
}

.product-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
  position: relative;
  width: 430px; 
}

.contact-btn {
  background-color: var(--color-main);
  color: var(--color-text);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 250px;
  padding-left: 30px;
  font-size: medium;

}

.contact-btn:hover {
  background-color: var(--color-text);
  color: var(--color-main);
}

.lower-part {
  margin-top: 1rem;
}

.product-description {
  color: var(--color-text);
  text-align: justify;
}

.product-controls select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--color-main);
  border-radius: 0.3rem;
  background-color: white;
  color: var(--color-text);
}

.product-controls select::-ms-expand {
  display: none; 
}

.product-controls::after {
  content: "\25BE"; 
  position: absolute;
  top: calc(50% - 1.3rem);
  right: 11.5rem;
  font-size: 1.8rem;
  pointer-events: none; 
  color: var(--color-text);
}

@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
    align-items: center;
  }

  .upper-part {
    flex-direction: row;
    align-items: flex-start;
  }

  .product-image {
    width: 135px;
    height: 160px;
  }

  .product-info {
    flex: 1;
    order: 1;
  }

  .product-info h3 {
    font-size: large;
  }

  .product-info p {
    font-size: large;
  }

  .product-controls {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    order: 2;
  }

  .product-controls select {
    width: 100%;
    font-size: small;
  }

  .product-controls::after {
    top: calc(50% - 2.7rem);
    right: 1rem;
  }

  .contact-btn {
    width: 100%;
    font-size: small;
    padding-left: 80px;
  }

  .lower-part {
    font-size: small;
  }

  .product-description {
    font-size: small;
  }

}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


:root {
  --color-background: #fef6e4;
  --color-text: #001858;
  --color-main: #F5AA89;
  --color-main-background: #F8F6F5;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  scroll-padding-top: 2rem;
  list-style: none;
  text-decoration: none;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 5px;
  background: var(--color-background);
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
}

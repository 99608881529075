.home {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-main-background);
  position: relative;
}

.slider-container {
  width: 100%;
  max-width: 1400px;
  margin-top: 4rem;
}

.slider-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.home h1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.home p {
  font-size: 1rem;
  margin: 0.4rem 0 1.8rem;
  text-transform: uppercase;
}

.home button {
  padding: 10px 14px;
  background: var(--color-main);
  color: var(--color-text);
  border-radius: 0.3rem;
  font-size: 14px;
  border-color: transparent;
  z-index: 9999;
}

.home button:hover {
  background-color: var(--color-text);
  color: var(--color-main);
}

.section-image {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80vh;
  height: auto;
  top: 350px;
  z-index: 2;
}

@media (max-width: 768px) {
  .slider-image {
    height: 200px;
  }

  .home h1 {
    padding-top: 100px;
  }

  .home p {
    font-size: 0.9rem;
    text-align: center;
  }

  .home button {
    font-size: 12px;
    align-items: center;
  }

  .section-image {
    top: 475px;
    height: auto;
    width: 600px;
  }
}

@media (max-width: 600px) {

  .section-image {
    top: 600px;
    height: auto;
    width: 350px;
  }
}
